// src/components/utils/addNotification.js  
import axios, { AxiosError } from "axios"

// addNotification
export const addNotification = (message, content, type, agency, redirect) => {
  try {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/addNotification`, {
      agency: agency,
      title: message, // "Failed to fetch annotation list"
      content: content, // `There was a failure attempting to fetch the annotation list. Please try again later.`
      severity: type, // "error"
      redirect: redirect
    })
  } catch (error) {
    if (typeof error === AxiosError) {
      console.log("axios error"); // Forced to console error due to no other logging mechanism
    }
    console.log(error)
  }
}

// expo