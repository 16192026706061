import axios from "axios"
import NotifPopupModal from "./NotifPopupModal"
import { useState, useEffect } from "react"

const NotifCard = (props) => {
    const { notification, inboxNotifications, setInboxNotifications, date } = props

    const [active, setActive] = useState(false)
    const [hasRead, setHasRead] = useState(notification.hasRead.BOOL)
    
    const id = notification.id.S
    const title = notification.title?.S
    const content = notification.content?.S
    const severity = notification.severity?.S
    const redirectId = notification.redirect?.S

    useEffect(()=>{
       setHasRead(notification.hasRead.BOOL) 
    }, [])

    // TODO: Extract severity Colors and import to allow for easy change as more severity types are added
    const severityColors =  {
        'info': '#525252',
        'success': '#32D583',
        'warning': '#F97066'
    }

    const borderStyle = !hasRead? {
        borderRight: '0.5px solid rgba(225,225,225,0.32)',
        borderBottom: '0.5px solid rgba(225,225,225,0.32)',
        borderTop: '0.5px solid rgba(225,225,225,0.32)',
        borderLeft: `5px solid ${severityColors[severity]}`,
    } : {
        borderLeft: `5px solid ${severityColors[severity]}`,
    }

    const styles = {
        notifCard: {
            ...borderStyle,
            padding: '14px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            borderTopRightRadius: '8px',   
            borderBottomRightRadius: '8px'
        },
        notifContent: {
            display:'flex', 
            flexDirection:'column', 
        },
        unreadNotification: {
            height: '10px',
            width: '10px',
            backgroundColor: '#3B82F6',
            borderRadius: '50%',
            justifySelf: 'right',
            alignSelf: 'right',
            margin: 'auto',
            marginRight: '0px'
        }
    }

    const handleClick = async () =>{
        setActive(true)
        setHasRead(true)
        const updatedInboxNotifications = inboxNotifications.map(notif => {
            if (notif.id.S === id) {
              return { ...notif, hasRead: {'BOOL': true} };
            }
            return notif;
          });

        setInboxNotifications(updatedInboxNotifications)

        // TODO
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/prod/updateNotification`, {
            agency: notification.agency.S,
            notificationIds: [id]
        })
    }

    return (
        <div style={styles.notifCard} className="notif-card" onClick={handleClick}>
            <div style={styles.notifContent}>
                <p style={{fontSize: '18px', lineHeight: '20px'}}>{title}</p>
                <p style={{lineHeight: '20px', color: 'grey'}}>{`${content}`}</p>
            </div>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <p style={{color: 'grey', textAlign: 'right'}}>{date}</p>
                <p style={{color: 'grey'}}>{`Intersection ID: ${redirectId}`}</p>
            </div>
            {active && <NotifPopupModal handleNotifRedirect={props.handleNotifRedirect} notification={notification} setActive={setActive}/>}
        </div>
    )
}
export default NotifCard