import React, { useRef, useState, useEffect } from 'react';
import { Stage, Layer, Circle, Image, Rect, Shape, Line } from 'react-konva';
import useImage from 'use-image';
import Tooltip from '@mui/material/Tooltip'
import axios from 'axios'
import { Collapse, IconButton } from '@mui/material';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import PolygonPicker from './atoms/PolygonPicker'
import { addNotification } from '../util/addNotification';
import PreviousAnnotationSelect from './annotation/PreviousAnnotationSelect'
import { addToLogs } from '../util/logging';


// Be very careful redoing this list! Parts of the Annotation code rely on the order of these (see Basic Info section of render)
const dataMapping = [
    { id: 0, name: 'center', coordinates: [], color: '#0053A1', common_name: "Center", desc: "Center of intersection." },
    { id: 1, name: 'EB', coordinates: [], color: '#A12500', common_name: "EB", desc: "Eastbound." },
    { id: 2, name: 'WB', coordinates: [], color: '#A10083', common_name: "WB", desc: "Westbound." },
    { id: 3, name: 'NB', coordinates: [], color: '#00A189', common_name: "NB", desc: "Northbound." },
    { id: 4, name: 'SB', coordinates: [], color: '#00A14B', common_name: "SB", desc: "Southbound." },
    { id: 5, name: 'EB_zone', coordinates: [], color: '#A1009B', common_name: "EB Zone", desc: "Eastbound zone." },
    { id: 6, name: 'WB_zone', coordinates: [], color: '#4BA100', common_name: "WB Zone", desc: "Westbound zone." },
    { id: 7, name: 'NB_zone', coordinates: [], color: '#4800A1', common_name: "NB Zone", desc: "Northbound zone." },
    { id: 8, name: 'SB_zone', coordinates: [], color: '#0078A1', common_name: "SB Zone", desc: "Southbound zone." },
    { id: 9, name: 'queue_thru', coordinates: [], color: '#A10096', common_name: "Queue Thru", desc: "Area for target through movement. After stopbar with a long detection area." },
    { id: 10, name: 'queue_left', coordinates: [], color: '#A10000', common_name: "Queue Left", desc: "Area for target left movement. After stopbar with a long detection area." },
    { id: 11, name: 'ped_zone', coordinates: [], color: '#6EA100', common_name: "Ped Zone", desc: "Pedestrian zone. Should be around pedestrian signal." },
    { id: 12, name: 'rlr_thru', coordinates: [], color: '#A12000', common_name: "RLR Thru", desc: "Red light running through. Should be beyond stopbar with small detection area." },
    { id: 13, name: 'rlr_left', coordinates: [], color: '#03A100', common_name: "RLR Left", desc: "Red light running left. Should be beyond stopbar with small detection area." },
    { id: 14, name: 'speed_zone', coordinates: [], color: '#B4B4B4', common_name: "Speed Track Zone", desc: "Rectangle where speed should be tracked." },
    { id: 15, name: 'lane_line', coordinates: [], color: '#B4B4B4', common_name: "Lane Length", desc: "Marks one lane length. Used in speed tracking." }
]

// For the future: Rendering of POI (point of interest) and manual input elements is done in order. Put POI elements first.
const analyticRequirements = [
    { name: 'LongDuration', needs: ['center', 'EB', 'WB', 'NB', 'SB', 'speed_zone', 'lane_line', 'lane_line_ft', 'long_wait_threshold'], common_name: 'Long Duration' },
    { name: 'RLR', needs: ['rlr_thru', 'rlr_left', 'EB', 'WB', 'NB', 'SB', 'speed_zone', 'lane_line', 'rlr_direction', 'left_phase', 'thru_phase', 'lane_line_ft', 'controller_data'], common_name: 'Red Light Running' },
    { name: 'YLR', needs: ['rlr_thru', 'rlr_left', 'EB', 'WB', 'NB', 'SB', 'speed_zone', 'lane_line', 'lane_line_ft', 'rlr_direction', 'left_phase', 'thru_phase', 'controller_data'], common_name: 'Yellow Light Running' },
    { name: 'GapAcceptance', needs: ['EB_zone', 'WB_zone', 'NB_zone', 'SB_zone', 'EB', 'WB', 'NB', 'SB', 'speed_zone', 'lane_line', 'lane_line_ft', 'gap_config'], common_name: 'Gap Acceptance' },
    { name: 'SplitFailure', needs: ['queue_thru', 'queue_left', 'EB', 'WB', 'NB', 'SB', 'speed_zone', 'lane_line', 'lane_line_ft', 'queue_thru_threshold', 'queue_left_threshold', 'left_phase', 'thru_phase', 'controller_data'], common_name: 'Split Failure' },
    { name: 'PedConflict', needs: ['ped_zone', 'EB', 'WB', 'NB', 'SB', 'speed_zone', 'lane_line', 'lane_line_ft', 'ped_conflict_dir',], common_name: 'Pedestrian Conflict' },
    // { name: 'BaseRequirements', needs: ['EB', 'WB', 'NB', 'SB', 'speed_zone', 'start_date', 'lane_line_ft'], common_name: 'Base Requirements' }
]

const base_requirements = ['EB', 'WB', 'NB', 'SB', 'speed_zone', 'lane_line', 'lane_line_ft', 'start_date']

const json_template = {
    "type": "vehicle only",
    "left_phase": 0,
    "thru_phase": 0,
    "short_track_threshold": 0.5,
    "delta_sec": 0,
    "center": [],
    "long_wait_threshold": 0,
    "rlr_direction": "",
    "rlr_thru": [],
    "rlr_left": [],
    "EB": [],
    "WB": [],
    "NB": [],
    "SB": [],
    "EB_zone": [],
    "WB_zone": [],
    "NB_zone": [],
    "SB_zone": [],
    "gap_config": [],
    "queue_thru": [],
    "queue_left": [],
    "queue_thru_threshold": 0,
    "queue_left_threshold": 0,
    "ped_zone": [],
    "ped_conflict_dir": "",
    "speed_zone": [],
    "lane_line": [],
    "lane_line_ft": 12
}


const manualInputTypes = [
    { name: 'ped_conflict_dir', type: 'select', options: ['EB', 'NB', 'WB', 'SB'], common_name: "Ped Conflict Direction", desc: "Direction name for vehicles that may conflict the pedetrians in the defined pedestrian zone." },
    { name: 'left_phase', type: 'number', min: 1, max: 8, common_name: "Left Phase", desc: "Phase number for target left movement." },
    { name: 'thru_phase', type: 'number', min: 1, max: 8, common_name: "Thru Phase", desc: "Phase number for target thru movement." },
    { name: 'short_track_threshold', type: 'number', min: 0.1, max: 1.0, default: 0.5, step: 0.1, common_name: "Short Track Threshold", desc: "Threshold to filter out short trajectories in seconds." },
    { name: 'delta_sec', type: 'number', min: 0, max: 200, default: 0, common_name: "Delta Sec", step: 0.5, desc: "The difference between physical time and recorded time from video latency." },
    { name: 'long_wait_threshold', type: 'number', min: 1, max: 100, default: 6, common_name: "Long Wait Threshold", desc: "Threhold to filter long wait issue in seconds." },
    { name: 'rlr_direction', type: 'select', options: ['EB', 'NB', 'WB', 'SB'], common_name: "RLR Direction", desc: "Direction for red light running direction analytics." }, // needs controller
    {
        name: 'gap_config', type: 'multi_select', options: ['EBL-WBT', 'WBL-EBT', 'SBL-NBT', 'NBL-SBT'],
        common_name: "Gap Configuration", desc: "List of different configurations for different gap acceptance."
    }, // List of list
    { name: 'queue_thru_threshold', type: 'number', min: 1, max: 10, default: 3, common_name: "Queue Thru Threshold", desc: "Number of vehicles in target thru movement queue detection zone to be identified as occupied." },
    { name: 'queue_left_threshold', type: 'number', min: 1, max: 10, default: 2, common_name: "Queue Left Threshold", desc: "Number of vehicles in target left movement queue detection zone to be identified as occupied." },
    {
        name: 'controller_data', type: 'file', accept: ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
        common_name: "Controller Data", desc: "Spreadsheet of controller data associated with this signal and timeframe."
    },
    { name: 'start_date', type: 'datetime', common_name: "Video Start Date", desc: "Date and time of the video.", step: "1" },
    { name: 'lane_line_ft', type: 'number', min: 6, max: 20, default: 12, common_name: "Lane Width", desc: "Lane width in feet." }
]

const directions = ['EB', 'WB', 'NB', 'SB', 'EB_zone', 'WB_zone', 'NB_zone', 'SB_zone']

const Annotation = (props) => {
    const stageRef = useRef(null);

    const [points, setPoints] = useState([]);
    const [selectedMetric, setSelectedMetric] = useState(dataMapping[3])
    const [zones, setZones] = useState([])
    const [zoning, setZoning] = useState(false)
    const [currentMousePos, setCurrentMousePos] = useState({ x: 0, y: 0 });
    const [inCanvas, setInCanvas] = useState(false)
    const [stateHistory, setStateHistory] = useState([{ points: [], selectedMetric: dataMapping[3], zones: [] }])
    const [stateHistoryIndex, setStateHistoryIndex] = useState(0)
    const [status, setStatus] = useState()
    const [prevAnnotations, setPrevAnnotations] = useState(false)

    const [manualInputs, setManualInputs] = useState([{ name: 'queue_thru_threshold', value: '3' }, { name: 'left_phase', value: '4' }, { name: 'thru_phase', value: '4' },
    { name: 'short_track_threshold', value: '0.5' }, { name: 'delta_sec', value: '0' }, { name: 'long_wait_threshold', value: '6' }, { name: 'queue_left_threshold', value: '2' },
    { name: 'lane_line_ft', value: '12' }])

    const [open, setOpen] = useState(null)

    const [image] = useImage(props.url);

    const originalImgHeight = image?.naturalHeight
    const originalImgWidth = image?.naturalWidth

    const CANVAS_WIDTH = 1280 //originalImgWidth<1200?originalImgWidth: 1200;
    const CANVAS_HEIGHT = 720 //originalImgHeight<720?originalImgHeight:720;
    const STAGE_WIDTH = CANVAS_WIDTH // window.innerWidth - 600 // 599 = table width
    const STAGE_HEIGHT = CANVAS_HEIGHT //window.innerHeight - 100 // 100 = nav bar height

    const calculateRatio = (num_1, num_2) => {
        for (let num = num_2; num > 1; num--) {
            if (num_1 % num === 0 && num_2 % num === 0) {
                num_1 = num_1 / num
                num_2 = num_2 / num
            }
        }
        let ratio = num_1 / num_2

        return ratio
    }

    const widthRatio = calculateRatio(originalImgWidth, CANVAS_WIDTH)
    const heightRatio = calculateRatio(originalImgHeight, CANVAS_HEIGHT)
    const width = originalImgWidth / widthRatio //originalImgWidth < CANVAS_WIDTH ? originalImgWidth : originalImgWidth / widthRatio
    const height = originalImgHeight / heightRatio // originalImgHeight < CANVAS_HEIGHT ? originalImgHeight : originalImgHeight / heightRatio

    useEffect(() => {
        if (props.prevSelected) {
            parsePrevInfoToState()
        }
    }, [])

    useEffect(() => {
        getStartDateDefaultValue()
    }, [])

    useEffect(() => {
        if (!props.agency || !props.info.intersection.S) {
            return
        }

        getPrevConfigs()

    }, [props.agency, props.info.intersection.S])

    const getPrevConfigs = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/getPrevConfigs`, {
                'intersection': props.info.intersection.S,
                'agency': props.agency
            })
            if (response.data) {
                // console.log(response.data)
                if (response.data.length > 0) {
                    setPrevAnnotations(response.data)
                    document.getElementById('prev-dialog').showModal()
                }
            }
            else throw new Error();
        } catch (err) {
            console.error(err)
            addToLogs("WARNING", "Couldn't fetch previous configs.", `Previous configs failed to fetch for ${props.agency} at intersection ${props.info.intersection.S}.\nError: ${err}`, "Annotation", props.agency)
        }
    }

    const handleStageClick = (e) => {
        if (!zoning) {
            setZoning(true)
        }
        const point = e.target.getStage().getPointerPosition();
        const isCloseToExistingPoint = points.some((existingPoint) => {
            const distance = Math.sqrt(
                (existingPoint.x - point.x) ** 2 + (existingPoint.y - point.y) ** 2
            );
            return distance < 10;
        });

        const exists = zones.some(zone => zone.type === selectedMetric.name);

        if (selectedMetric.id === 15 && points.length === 1 && !exists) {
            addHistory([], selectedMetric, [...zones, { type: selectedMetric.name, color: selectedMetric.color, coordinates: [...points, point] }])
            // console.log('Adding lane line!', points, point)
            setZoning(false)
            return
        }

        // Closing out a zone
        else if (isCloseToExistingPoint && points.length > 2) {
            // Check if zone already exists
            if (exists) {
                addHistory([], selectedMetric, zones)
                return
            }
            // console.log(`Points for new zone ${selectedMetric.name}: ${JSON.stringify(points)}`)
            if (selectedMetric.id === 14 && points.length !== 4) {
                return
            } else {
                addHistory([], selectedMetric, [...zones, { type: selectedMetric.name, color: selectedMetric.color, coordinates: points }])
            }
            return
        }

        if (!isCloseToExistingPoint) {
            addHistory([...points, point], selectedMetric, zones)
        }
    };


    const handleZoneDelete = (index) => {
        const updatedZones = [...zones];
        updatedZones.splice(index, 1);
        addHistory([], selectedMetric, updatedZones)
    };


    const handleMouseMove = (e) => {
        setCurrentMousePos(e.target.getStage().getPointerPosition());
    };


    const handleMetricClick = (o) => {
        const metricClicked = o.id

        // Metric is the same as selected
        if (metricClicked === selectedMetric.id) {
            return
        }
        addHistory([], o, zones)

    }


    const doneRequest = () => {
        findMetricNeeds()
        const dia = document.getElementById("done-dialog")
        dia.showModal()
    }


    const findMetricNeeds = () => {
        // Cycle through requirements and collect which are completed at which aren't
        var status = []
        analyticRequirements.forEach((req) => {
            status.push({ name: req.name, status: analayticColorChange(req).color === '#00A19C' ? true : false, common_name: req.common_name })
        })
        setStatus(status)

    }

    // History functions

    /*
     * Author: Robby Rice
     * Slices the beginning of history to the index, appends new history on, sets this as the current values, and resets the history index.
    */
    const addHistory = (points, selectedMetric, zones) => {
        var currentHistory;
        if (stateHistoryIndex !== stateHistory.length) {
            currentHistory = stateHistory.slice(0, stateHistoryIndex + 1)
        }
        else {
            currentHistory = stateHistory
        }

        currentHistory.push({
            points, selectedMetric, zones
        })
        setStateHistory(currentHistory)
        setStateHistoryIndex(currentHistory.length)
        setPoints(points)
        setSelectedMetric(selectedMetric)
        setZones(zones)
    }

    /*
     * Author: Robby Rice
     * Goes back one in state history if available. Sets index back one, sets variables back, but doesn't reset history in case of a redo.
    */
    const handleUndo = () => {
        if (stateHistoryIndex <= 0) {
            alert('Nothing to undo.')
            return
        }
        const historyMoment = stateHistory[stateHistoryIndex - 1]
        setStateHistoryIndex(curr => curr - 1)
        setPoints(historyMoment.points)
        setSelectedMetric(historyMoment.selectedMetric)
        setZones(historyMoment.zones)
    }

    /*
     * Author: Robby Rice
     * Goes up in history if avaiable. Sets variables and ups index. Does not affect state history.
    */
    const handleRedo = () => {
        if (stateHistoryIndex >= stateHistory.length) {
            alert('Nothing to redo.')
            return
        }
        const historyMoment = stateHistory[stateHistoryIndex]
        setStateHistoryIndex(curr => curr + 1)
        setPoints(historyMoment.points)
        setSelectedMetric(historyMoment.selectedMetric)
        setZones(historyMoment.zones)
    }

    /*
     * Author: Robby Rice
     * Adds new state with no zones or points and sets these variables.
    */
    const handleRestart = () => {
        addHistory([], selectedMetric, [])
    }

    // End history functions

    function translateCoordinates(desiredWidth, desiredHeight, coordinates) {

        const horizontalRatio = desiredWidth / CANVAS_WIDTH;
        const verticalRatio = desiredHeight / CANVAS_HEIGHT;

        const translatedCoordinates = coordinates.map(([x, y]) => {
            const translatedX = x * horizontalRatio;
            const translatedY = y * verticalRatio;
            return [translatedX, translatedY];
        });

        return translatedCoordinates;
    }

    const handleSubmit = async () => {
        document.getElementById('submit-button').style.cursor = 'not-allowed';
        var json = json_template

        // Other form input data unrelated to zones
        const signal_id = props.info.intersection.S
        const ped_input = status.find(obj => obj.name === 'PedConflict' && obj.status === true) ? 'include ped' : 'vehicle only'
        let start_date = manualInputs.find(obj => obj.name === 'start_date').value

        // Make sure start date has seconds
        if (start_date && start_date.length === 16) {
            start_date += ':00';
        }

        json['type'] = ped_input

        // Get zone requirements

        for (const zone of zones) {
            const type = zone.type;
            var cords = []
            for (const cord of zone.coordinates) {
                cords.push([parseInt(cord.x), parseInt(cord.y)])
            }
            const translated_cords = translateCoordinates(originalImgWidth, originalImgHeight, cords)
            // console.log(`${JSON.stringify(cords)} now at ${JSON.stringify(translated_cords)}`)
            json[type] = translated_cords
        }

        // Get nonzone requirements

        // Get all form inputs (outside of gap_config, process after this)
        const selectedOptions = [];
        for (const event of manualInputs) {
            if (event.name.startsWith('gap_config')) {
                const direction = event.name.slice(-7)
                selectedOptions.push([direction, event.value])
                continue
            }
            if (!isNaN(event.value)) {
                json[event.name] = parseInt(event.value)
            } else {
                json[event.name] = event.value
            }
        }

        // Obtain gap_config config correctly

        for (const obj of selectedOptions) {
            json['gap_config'].push(parseGapConfig(obj))
        }

        // Get analytics
        const selectedAnalytics = status.filter(item => item.status === true).map(item => item.name)

        const outer_json = {}
        outer_json[signal_id] = json

        var metadata = {}
        metadata["config"] = outer_json
        metadata["start_date"] = start_date
        metadata["analytics"] = selectedAnalytics
        metadata["url"] = props.url
        metadata["image_name"] = extractTextFromUrl(props.url) !== null ? extractTextFromUrl(props.url) : signal_id;
        metadata["folder_name"] = `${props.agency}/${signal_id}`
        metadata["id"] = props.info.id.S
        metadata["vid_name"] = props.info.vid_name.S

        console.log(JSON.stringify(metadata))

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/startAnalytics`, metadata)
            if (response.data) props.handleFinish(metadata.id);
            else throw new Error;
        } catch (err) {
            addNotification(
                "Failed to start analytics",
                `There was a failure attempting to start analytics. Please try again later.`,
                "error",
                props.agency
            );
            addToLogs("ERROR", "Failed to start analytics.", `Analytics for ${props.agency} failed to start.\nError: ${err}\nMetadata: ${JSON.stringify(metadata)}.`, "Annotation", props.agency)
        }
        document.getElementById('submit-button').style.cursor = 'cursor';
    }

    function extractTextFromUrl(url) {
        const regex = /results\/([^_]+)_image/;
        const match = url.match(regex);
        if (match && match.length >= 2) {
            return match[1];
        }
        return null;
    }

    const analayticColorChange = (requirement) => {
        // Grab base requirements
        var baseRequirements = true
        base_requirements.forEach((req) => {
            // Search through zones
            const base_zone_req = zones.find(obj => obj.type === req)

            // Search through manual inputs
            const manual_req = manualInputs.find(obj => obj.name === req)

            if (!base_zone_req && !manual_req) {
                baseRequirements = false
                return
            }
        })

        // Grab requirement specific analytic
        const totalReq = requirement.needs.length
        var totalReqDone = 0
        var analyticRequirements = true
        requirement.needs.forEach((req) => {
            // Search through zones
            const base_zone_req = zones.find(obj => obj.type === req)

            // Search through manual inputs
            var manual_req = manualInputs.find(obj => obj.name === req)

            // Handle gap config's variable name
            if (req === 'gap_config') {
                // Check if any manual input starts with 'gap_config'
                const gapConfigManualInput = manualInputs.find(obj => obj.name.startsWith('gap_config'));

                // Set gap_config_found to true if a corresponding manual input is found
                if (!gapConfigManualInput) {
                    analyticRequirements = false;
                } else {
                    manual_req = true
                }
            }

            if (!base_zone_req && !manual_req) {
                analyticRequirements = false
            } else {
                totalReqDone++;
            }
        })

        return { color: baseRequirements && analyticRequirements ? '#00A19C' : '#D0D5DD', total: `${totalReqDone}/${totalReq}` }
    }

    const parseGapConfig = (obj) => {
        // console.log(obj)
        const str = obj[0].slice(-7)

        const strTranslate = (str) => {
            switch (str) {
                case 'T':
                    return 'Thru';
                case 'R':
                    return 'Right';
                case 'L':
                    return 'Left';
                default:
                    return;
            }
        }

        return [str, str.substring(0, 2), strTranslate(str.charAt(2)), str.substring(4, 6), strTranslate(str.charAt(6)), obj[1]]
    }

    const handleManualInputChange = (e) => {
        var val = e.target.value;
        var name = e.target.name;

        // In gap config, remove -select suffix if select is changed
        if (name.endsWith('-select')) {
            name = name.slice(0, -7);

            // Return if the corresponding checkbox isn't checked
            const correspondingCheckbox = document.getElementsByName(name)
            const checkboxElement = correspondingCheckbox[0]
            if (checkboxElement && !checkboxElement.checked) {
                // console.log(`Changed value for ${name} but not selected.`)
                return
            }
        }


        // Gap config uniquely lets the user remove items from their manual input, while others just add or change values.
        // We need to detect if a name was already in our manualInputs list and remove it if the checkbox is unchecked.
        if (name.startsWith('gap_config')) {
            const correspondingCheckbox = document.getElementsByName(name)
            const checkboxElement = correspondingCheckbox[0]
            const index = manualInputs.findIndex(obj => obj.name === name);

            if (checkboxElement && !checkboxElement.checked && index !== -1) {
                const updatedManualInputs = [...manualInputs];
                updatedManualInputs.splice(index, 1); // Remove the item at the found index
                // console.log(updatedManualInputs)
                setManualInputs(updatedManualInputs);
                return
            }
        }


        // If this change is coming from a checkbox on gap config, we need to adjust the val to correspond with
        // the select box. Removing the onChange handler will not add a gap config option if they want the default select
        // which is EB currently.
        if (name.startsWith('gap_config')) {
            const selectValues = document.getElementsByName(`${name}-select`)
            const selectElement = selectValues[0]
            const selectedIndex = selectElement.selectedIndex;
            const selectedOption = selectElement.options[selectedIndex]
            const selectedVal = selectedOption.value;
            val = selectedVal
        }

        // Ensure date has seconds
        if (name === 'start_date') {
            val = val.length === 16 ? `${val.slice(0, 16)}:00` : val;
        }

        // Check if an element with the same name already exists
        const index = manualInputs.findIndex(obj => obj.name === name);

        if (index !== -1) {
            // If the name exists, update the value
            const updatedInputs = manualInputs.map((input, i) =>
                i === index ? { name: input.name, value: val } : input
            );
            setManualInputs(updatedInputs);
            // console.log(updatedInputs)
        } else {
            // If the name doesn't exist, add it as a new entry
            setManualInputs([...manualInputs, { name: name, value: val }]);
            // console.log([...manualInputs, { name: name, value: val }])
        }
    };

    const getStartDateDefaultValue = () => {
        const startDateInput = manualInputs.find(obj => obj.name === 'start_date');
        const startDateMetaData = props.startDate

        if (startDateInput) {
            const newManualInputs = [...manualInputs, {name: 'start_date', value: startDateInput.value}]
            document.getElementById('annotation-start_date').defaultValue = startDateInput.value
            setManualInputs(newManualInputs)
            // return startDateInput.value;
        }
        else if (startDateMetaData) {
            const newManualInputs = [...manualInputs, {name: 'start_date', value: startDateMetaData.S}]
            console.log(newManualInputs)
            setManualInputs(newManualInputs)
            document.getElementById('annotation-start_date').defaultValue = startDateMetaData.S
            // return startDateMetaData.S
        }
        // If no 'start_date' input found, set a default value
        // return new Date().toISOString().slice(0, 19);
    };

    const parsePrevInfoToState = () => {
        if (!props.prevSelected) {
            return
        }
        const intersection = props.info.intersection.S
        const prevConfigParse = JSON.parse(props.info.config.S)
        const prevConfig = prevConfigParse[intersection]
        addToLogs("INFO", "User using previous annotation.", `User using previous annotation for annotating.\nConfig: ${prevConfig}.`, "Annotation", props.agency)

        // Manual inputs
        const newManualInputs = [...manualInputs]
        const configKeys = Object.keys(prevConfig)

        for (const idx in manualInputs) {
            const foundInputIdx = configKeys.findIndex((name) => name === manualInputs[idx].name)
            // console.log(foundInputIdx)
            if (foundInputIdx !== -1) {
                const inputName = configKeys[foundInputIdx]
                const value = prevConfig[inputName]
                newManualInputs[idx] = { name: inputName, value: value.toString() }
            }
        }
        // console.log(newManualInputs)
        setManualInputs(newManualInputs)
        // TODO: Set input boxes to new manual inputs as it doesn't update automatically, including start date

        for (const { name, value } of manualInputs) {
            let elements = document.getElementsByName(name)
            if (elements.length > 0) {
                for (const element of elements) {
                    // Set the value property of each element
                    element.value = value;
                }
            }
        }
        // Treat start date a bit different
        handleManualInputChange({
            target: {
                value: prevConfig['start_date'],
                name: 'start_date'
            }
        })
        document.getElementsByName('start_date')[0].value = prevConfig['start_date']

        // Zones
        // TODO: Check for translation in QA
        // Add zones
        const zoneObj = []
        for (const [k, v] of Object.entries(prevConfig)) {
            if (Array.isArray(v) && v.length > 1) {
                // Get color
                const dmObj = dataMapping.find((obj) => obj.name === k)
                if(!dmObj) {
                    console.log(dmObj, k, v)
                    continue
                }
                const color = dmObj?.color ?? '#FFF'

                // Get coordinates object [{x: ..., y: ...}]
                const coordsMapping = v.map(points => ({ x: points[0], y: points[1] }));
                zoneObj.push({ type: dmObj.name, color: color, coordinates: coordsMapping })
            }
        }

        addHistory([], selectedMetric, zoneObj)
        findMetricNeeds()

    }

    const parsePrevAnnotationToState = (id) => {
        const selectedItem = prevAnnotations.find(anno => anno.id === id)
        const config = JSON.parse(selectedItem.config)

        const prevConfig = config[selectedItem['intersection']]
        const newManualInputs = [...manualInputs]
        const configKeys = Object.keys(prevConfig)

        for (const idx in manualInputs) {
            const foundInputIdx = configKeys.findIndex((name) => name === manualInputs[idx].name)
            // console.log(foundInputIdx)
            if (foundInputIdx !== -1) {
                const inputName = configKeys[foundInputIdx]
                const value = prevConfig[inputName]
                newManualInputs[idx] = { name: inputName, value: value.toString() }
            }
        }
        // console.log(newManualInputs)
        setManualInputs(newManualInputs)
        // TODO: Set input boxes to new manual inputs as it doesn't update automatically, including start date

        for (const { name, value } of manualInputs) {
            let elements = document.getElementsByName(name)
            if (elements.length > 0) {
                for (const element of elements) {
                    // Set the value property of each element
                    element.value = value;
                }
            }
        }
        // Treat start date a bit different
        handleManualInputChange({
            target: {
                value: prevConfig['start_date'],
                name: 'start_date'
            }
        })
        document.getElementsByName('start_date')[0].value = prevConfig['start_date']

        // Zones
        // TODO: Check for translation in QA
        // Add zones
        const zoneObj = []

        for (const [k, v] of Object.entries(prevConfig)) {
            if (Array.isArray(v) && v.length > 1) {
                // Get color
                const dmObj = dataMapping.find((obj) => obj.name === k)
                const color = dmObj?.color ?? '#FFF'

                // Get coordinates object [{x: ..., y: ...}]
                const coordsMapping = v.map(points => ({ x: points[0], y: points[1] }));
                zoneObj.push({ type: dmObj.name, color: color, coordinates: coordsMapping })
            }
        }

        addHistory([], selectedMetric, zoneObj)
        findMetricNeeds()

        document.getElementById('prev-dialog').close()
    }

    // Run when props.prevSelected is true
    const handleReannotationDoneRequest = () => {
        // Run preRereun Lambda
        // console.log(props.info.id.S)
        try {
            // Non-blocking: this adjusts metadata and deletes results from results table, which doesn't interefere w/ analytics process
            // hence no .then or await
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/rerunAnalytics`, { 'id': props.info.id.S })
                .catch((err) => {
                    // Send notif
                    // TODO: Send to queue for deletion/ manual later
                })
        } catch (err) {
            console.log(err)
            addToLogs("ERROR", "Failed reannotation request.", `User submitted reannotation request and it failed.\nError: ${err}`, "Annotation", props.agency)
        }

        // Run handleSubmit normally
        handleSubmit()
    }


    return (
        <div style={{ 'backgroundColor': '#20202D', 'height': '100vh' }}>
            <div style={{ 'display': 'flex', 'flexDirection': 'row', 'marginLeft': '5px' }}>
                <div style={{ 'display': 'flex', 'flexDirection': 'column', 'width': STAGE_WIDTH }}>
                    <div style={{ 'marginTop': '10px', 'marginBottom': '10px' }}>
                        <h2>{props.info.vid_name.S}</h2>
                        <p style={{ 'color': '#D0D5DD' }}>{props.info.intersection.S}</p>
                    </div>
                    <Stage
                        width={STAGE_WIDTH} height={STAGE_HEIGHT}
                        onClick={handleStageClick} onMouseMove={handleMouseMove}
                        onMouseEnter={() => setInCanvas(true)} onMouseLeave={() => setInCanvas(false)}
                        ref={stageRef}
                    >
                        <Layer>
                            <Rect height={CANVAS_HEIGHT} width={CANVAS_WIDTH} fill="#fff" />
                            <Image height={height} width={width} image={image} />
                            {points.map((point, index) => (
                                <Circle
                                    key={index}
                                    x={point.x}
                                    y={point.y}
                                    radius={5}
                                    fill={selectedMetric?.color ?? "#fff"}
                                />
                            ))}
                            {zones.map((zone, index) => {
                                const { coordinates, color } = zone;

                                const shapeProps = {
                                    sceneFunc: (context, shape) => {
                                        context.beginPath();
                                        context.moveTo(coordinates[0].x, coordinates[0].y);

                                        coordinates.forEach((coord, i) => {
                                            if (i > 0) {
                                                context.lineTo(coord.x, coord.y);
                                            }
                                        });

                                        context.closePath();
                                        context.fillStrokeShape(shape);
                                    },
                                    fill: color,
                                    opacity: 0.5,
                                    key: index,
                                    onContextMenu: (e) => {
                                        e.evt.preventDefault();
                                        handleZoneDelete(index);
                                    }
                                };

                                const outlineProps = {
                                    sceneFunc: (context, shape) => {
                                        context.beginPath();
                                        context.moveTo(coordinates[0].x, coordinates[0].y);

                                        coordinates.forEach((coord, i) => {
                                            if (i > 0) {
                                                context.lineTo(coord.x, coord.y);
                                            }
                                        });

                                        context.closePath();
                                        context.strokeShape(shape);
                                    },
                                    stroke: color,
                                    strokeWidth: 3,
                                    opacity: 1,
                                    key: `outline-${index}`
                                };

                                const circles = coordinates.map((coord, i) => (
                                    <Circle
                                        key={`circle-${i}`}
                                        x={coord.x}
                                        y={coord.y}
                                        radius={4}
                                        fill={color}
                                    />
                                ));

                                return <>
                                    <Shape {...shapeProps} />
                                    <Shape {...outlineProps} />
                                    {circles}
                                </>;
                            })}
                            {zoning && points.length > 0 && selectedMetric && inCanvas && <Line
                                points={[points.at(-1).x, points.at(-1).y, currentMousePos.x, currentMousePos.y]}
                                strokeWidth={3}
                                stroke={selectedMetric.color} />}
                            {points && points.map((point, index) => {
                                if (index === 0) {
                                    return null;
                                }

                                const prevPoint = points[index - 1];

                                return (
                                    <Line
                                        key={`line-${index}`}
                                        points={[prevPoint.x, prevPoint.y, point.x, point.y]}
                                        stroke={selectedMetric.color}
                                        strokeWidth={4}
                                    />
                                );
                            })}
                        </Layer>
                    </Stage>
                    <div style={{ 'display': 'flex', 'flexDirection': 'row', 'height': '40px', 'justifyContent': 'center', 'marginTop': '5px', 'alignItems': 'center' }}>
                        {/* Color POI Indicator */}
                        <div style={{ 'flexGrow': 1, 'padding': '5px', 'backgroundColor': selectedMetric.color, 'borderRadius': '10px', 'marginRight': '5px' }}>
                            <h3>{selectedMetric.common_name}</h3>
                        </div>
                        {/* Progression Buttons */}
                        <div style={{ 'display': 'flex', 'alignItems': 'center' }}>
                            <button className='annotation-button' onClick={() => { handleUndo() }}>Undo</button>
                            <button className='annotation-button' onClick={() => { handleRedo() }}>Redo</button>
                            <button className='annotation-button' onClick={() => { handleRestart() }}>Restart</button>
                            <button className='annotation-button' style={{ 'backgroundColor': '#005856', 'color': '#D0D5DD' }}
                                onClick={() => { doneRequest() }}>Done</button>
                        </div>
                    </div>
                </div>
                {/* Side panel */}
                <div className='annotation-side-panel'>
                    <h2 style={{ 'fontWeight': '500', 'fontSize': '20px' }}>Annotation Requirements</h2>
                    <span style={{ 'borderTop': '2px solid #373848', 'width': '100%', 'marginTop': '5px', 'marginBottom': '10px' }}></span>

                    {/* Basic Info */}
                    <div style={{ 'marginLeft': '10px', 'marginRight': '10px' }}>

                        {/* Manual Inputs */}
                        <div style={{ 'display': 'flex', 'flexDirection': 'column' }}>
                            <div className='annotation-label-input-div'>
                                <label className='annotation-label'>Starting Date and Time</label>
                                <input className='annotation-input' id='annotation-start_date' onChange={(e) => handleManualInputChange(e) } 
                                    type='datetime-local' name={'start_date'} step={'1'} defaultValue={new Date().toISOString().slice(0, 19)} />
                            </div>
                            <div style={{ 'display': 'flex', 'flexDirection': 'row', 'justifyContent': 'space-between' }}>
                                <div className='annotation-label-input-div'>
                                    <label className='annotation-label'>Delta Sec</label>
                                    <input className='annotation-input' onChange={(e) => handleManualInputChange(e)} defaultValue={0}
                                        type='number' name={'delta_sec'} min={0} max={200} step={0.5} />
                                </div>
                                <div className='annotation-label-input-div'>
                                    <label className='annotation-label'>Short Track Threshold</label>
                                    <input className='annotation-input' onChange={(e) => handleManualInputChange(e)} defaultValue={0.5}
                                        type='number' name={'short_track_threshold'} min={0.1} max={1.0} step={0.1} />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Analytic Collapses */}
                    <div>
                        {analyticRequirements.map((requirement, index) => (
                            <div key={index}>
                                <div style={{
                                    'display': 'flex', 'flexDirection': 'row', 'justifyContent': 'space-between', 'backgroundColor': '#20202D', 'borderRadius': '8px',
                                    'padding': '5px', 'alignItems': 'center', 'marginBottom': '6px',
                                }} onClick={() => open === requirement.name ? setOpen(null) : setOpen(requirement.name)}>
                                    <div style={{ 'display': 'flex', 'flexDirection': 'row', 'alignItems': 'center' }}>
                                        <IconButton color='#999AAD' style={{ 'color': '#999AAD' }}>
                                            {open === requirement.name ? <KeyboardArrowDownIcon style={{ 'color': analayticColorChange(requirement).color }} /> : <KeyboardArrowRightIcon style={{ 'color': analayticColorChange(requirement).color }} />}
                                        </IconButton>
                                        <h4 style={{ 'color': analayticColorChange(requirement).color, 'fontWeight': 'normal' }}>{requirement.common_name}</h4>
                                    </div>

                                    <div>
                                        <p style={{ color: analayticColorChange(requirement).color, 'fontWeight': '300' }}>{analayticColorChange(requirement).total}</p>
                                    </div>

                                </div>

                                <Collapse in={open === requirement.name} >
                                    <div style={{
                                        'borderColor': 'red', 'borderWidth': '2px', 'padding': '10px', 'borderRadius': '6px',
                                        'flexDirection': 'column', 'display': 'flex', 'marginBottom': '3px', 'marginLeft': '15px', 'marginRight': '15px'
                                    }}>
                                        {requirement.needs.map((need, index) => {
                                            const mapping = dataMapping.find(obj => obj.name === need)

                                            // POI input
                                            if (mapping) {
                                                return (
                                                    <Tooltip title={mapping.desc}>
                                                        <PolygonPicker name={mapping?.common_name} onClick={() => { handleMetricClick(mapping) }}
                                                            selected={selectedMetric.id === mapping?.id} drawn={zones.find(obj => obj.type === mapping?.name)} />
                                                    </Tooltip>
                                                )
                                            }

                                            // Manual input type
                                            else {
                                                const formMapping = manualInputTypes.find(obj => obj.name === need)
                                                const manualInput = manualInputs.find(obj => obj.name === need)

                                                if (formMapping) {
                                                    switch (formMapping.type) {
                                                        case 'select':
                                                            return (
                                                                <div className='annotation-label-input-div'>
                                                                    <label className='annotation-label'>{formMapping.common_name}</label>
                                                                    <select className='annotation-input' name={need} onChange={(e) => handleManualInputChange(e)} required>
                                                                        {formMapping.options.map((option, index) => {
                                                                            return (
                                                                                <option className='annotation-input-option' value={option} selected={manualInput?.value === option} key={`${need}-${option}`}
                                                                                    name={`${need}-${option}`}>{option}</option>
                                                                            )
                                                                        })}
                                                                    </select>
                                                                </div>
                                                            )

                                                        case 'number':
                                                            return (
                                                                <div className='annotation-label-input-div'>
                                                                    <Tooltip title={formMapping.desc}>
                                                                        <label className='annotation-label'>{formMapping.common_name}</label>
                                                                    </Tooltip>
                                                                    <input className='annotation-input' type='number' onChange={(e) => handleManualInputChange(e)} min={formMapping?.min} max={formMapping?.max}
                                                                        defaultValue={manualInput ? manualInput?.value : formMapping?.default} step={formMapping?.step} name={need} required />
                                                                </div>
                                                            )

                                                        // Gap config
                                                        case 'multi_select':
                                                            return (
                                                                <div>
                                                                    <label>{formMapping.common_name}</label>
                                                                    <div style={{ border: '1px solid black', padding: '10px', 'borderRadius': '10px' }}>
                                                                        {formMapping.options.map((option, index) => {
                                                                            return (
                                                                                <div className=''>
                                                                                    <label className='annotation-label'>{option}</label>
                                                                                    <input className='annotation-checkbox' type='checkbox' onChange={(e) => handleManualInputChange(e)}
                                                                                        value={option} name={`${need}-${option}`} id={need} />
                                                                                    <select name={`${need}-${option}-select`} className='annotation-input'
                                                                                        onChange={(e) => handleManualInputChange(e)} style={{ 'marginBottom': '2px' }}>
                                                                                        {directions.map((direction, index) => {
                                                                                            return (<option selected={manualInput?.value === option} value={direction}>{direction}</option>)
                                                                                        })}
                                                                                    </select>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            )

                                                        case 'datetime':
                                                            return (
                                                                <div>
                                                                    <label>{formMapping.common_name}</label>
                                                                    <input onChange={(e) => handleManualInputChange(e)} defaultValue={manualInput ? manualInput?.value : formMapping?.default} type='datetime-local' name={need} step={formMapping?.step} />
                                                                </div>
                                                            )

                                                        case 'file':
                                                            return (
                                                                <div>
                                                                    <label>{formMapping.common_name}</label>
                                                                    <input type='file' accept={formMapping?.accept} name={need} />
                                                                </div>
                                                            )
                                                        default:
                                                            break
                                                    }
                                                }
                                            }
                                            return <></>
                                        })}
                                    </div>
                                </Collapse>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <dialog id="done-dialog">
                <div style={{ 'display': 'flex', 'flexDirection': 'column' }}>
                    <div style={{ 'display': 'flex', 'flexDirection': 'row', 'justifyContent': 'space-between', 'marginTop': 'none', 'marginBottom': '5px', 'alignItems': 'center' }}>
                        <h1>Selected Analytics</h1>
                        <button style={{ 'marginBottom': '10px', 'padding': '0', 'marginRight': '5px' }}
                            onClick={() => { document.getElementById('done-dialog').close() }}>x</button>
                    </div>
                    <p style={{ 'color': 'gray', 'paddingBottom': '5px' }}>Double check any manual input values before starting analytics.</p>
                    {!status && <p>No analytics available.</p>}
                    {status?.map((analytic) => {
                        return (
                            <p style={{ color: analytic.status ? '#32D583' : '#D0D5DD' }}>{analytic.common_name}</p>
                        )
                    })}
                    <div style={{ 'display': 'flex', 'justifyContent': 'center' }}>
                        <button id='submit-button' style={{ 'color': 'white', 'padding': '10px', cursor: status?.find(obj => obj.status) ? 'pointer' : 'not-allowed' }}
                            onClick={() => {
                                if (props.prevSelected) {
                                    handleReannotationDoneRequest()
                                } else {
                                    handleSubmit()

                                }
                            }} disabled={!status?.find(obj => obj.status)}
                        >Start Analytics</button>
                    </div>
                </div>

            </dialog>

            <dialog id='prev-dialog'>
                <div style={{ 'display': 'flex', 'flexDirection': 'column' }}>
                    <div style={{ 'display': 'flex', 'flexDirection': 'row', 'justifyContent': 'space-between', 'marginTop': 'none', 'marginBottom': '5px', 'alignItems': 'center' }}>
                        <h1>Previous Annotations</h1>
                        <button style={{ 'marginBottom': '10px', 'padding': '3px', 'marginRight': '5px' }}
                            onClick={() => { document.getElementById('prev-dialog').close() }}>x</button>
                    </div>
                    <p style={{ 'color': 'gray', 'paddingBottom': '5px' }}>Looks like you have annotations for this intersection. Load a previous annotation?</p>

                    <div style={{ 'marginLeft': '15px', 'marginRight': '15px', 'gap': '5px', 'marginBottom': '10px' }}>
                        {prevAnnotations && prevAnnotations.map((annotation) => {
                            return (
                                <div style={{ 'marginTop': '5px' }}>
                                    <PreviousAnnotationSelect id={annotation.id} title={annotation.vid_name} subtitle={new Date(annotation.start_date).toDateString()}
                                        config={annotation.config} image={image} onClick={() => parsePrevAnnotationToState(annotation.id)} width={width} height={height}
                                        intersection={annotation.intersection} />
                                </div>

                            )
                        })}
                    </div>

                    <div style={{ 'display': 'flex', 'justifyContent': 'center' }}>
                        <button style={{ 'color': 'white', 'padding': '10px' }}
                            onClick={() => {
                                document.getElementById('prev-dialog').close()
                            }}
                        >Cancel</button>
                    </div>
                </div>
            </dialog>
        </div>

    );
};

export default Annotation;
