import { useEffect, useState } from "react";
import Annotation from "./Annotation";
import axios from "axios";
import { AiOutlineArrowRight } from "react-icons/ai";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LinearProgress from "@mui/material/LinearProgress";
import { addNotification } from "../util/addNotification";
import CircularProgress from '@mui/material/CircularProgress';
import { addToLogs } from "../util/logging";

const AnnotationList = (props) => {
  const [selected, setSelected] = useState(null)
  const [list, setList] = useState(null)
  const [selectedLink, setSelectedLink] = useState(null)
  const [error, setError] = useState(false)
  const [prevSelected, setPrevSelected] = useState(false)
  const [deleteSelect, setDeleteSelect] = useState(null)

  useEffect(() => {
    const getAnnotationList = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/getAnnotationList`, { 'agency': props.agency })
        if (response.data || response) {
          const dataret = response.data;
          if (dataret.length === 0) {
            setList([]);
            return;
          } else {
            const groupedData = groupDataByStatus(dataret);
            setList(groupedData);
          }
        } else {
          throw new Error;
        }
      } catch (err) {
        addNotification(
          "Failed to fetch annotation list",
          `There was a failure attempting to fetch the annotation list. Please try again later.`,
          "error",
          props.agency
        );
        addToLogs("WARNING", "Couldn't fetch annotation list.", `Couldn't fetch annotation list.\nError: ${err}.`, "AnnotationList", props.agency)
        setError(true);
        return;
      }
    };

    getAnnotationList();
  }, [props.agency])

  function orderKeys(object) {
    const orderedObject = {};
  
    // Add 'annotate' key-value pair if present
    if (object.hasOwnProperty('annotate')) {
      orderedObject['annotate'] = object['annotate'];
      delete object['annotate']; // Remove 'annotate' from object
    }
  
    // Add other key-value pairs in the object
    Object.keys(object).forEach(key => {
      if (!(key in orderedObject)) {
        orderedObject[key] = object[key];
      }
    });
  
    // Add 'done' key-value pair if present
    if (object.hasOwnProperty('done')) {
      orderedObject['done'] = object['done'];
      delete object['done']; // Remove 'done' from object
    }
  
    return orderedObject;
  }

  const onSelect = async (item) => {
    setPrevSelected(false)
    setSelected(item)
    await getSelectedLink(item)
  }

  const onAnnotationFinish = () => {
    // Send notification
    addNotification(
      "Annotation started",
      `Annotation has been completed. Gathering analytics now!`,
      "success",
      props.agency
    );
    // TODO: list.filter is not a function. see what list structure is
    props.handleFinish(selected.id.S);

    // Filter out completed annotation from our local state
    const newList = { ...list };
    newList.annotate = newList.annotate.filter(item => item !== selected);
    setList(newList)

    setSelected(null)
    setSelectedLink(null)
    setPrevSelected(false)
  }

  const getSelectedLink = async (item) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/getAnnotatedImage`, { 'image_id': item.vid_name.S })
      if (response.data || response) setSelectedLink(response.data.data.toString())
      else throw new Error;
    } catch {
      addNotification(
        "Failed to fetch annotated image",
        `There was a failure attempting to fetch the annotated image. Please try again later.`,
        "error",
        props.agency
      );
      setError(true);
    }
  }

  // Group annotation list entries (from DynamoDB table hypersafe-metadata-table) by status
  const groupDataByStatus = (data) => {
    return data.reduce((acc, item) => {
      const status = item.status.S;
      if (!acc[status]) {
        acc[status] = [];
      }
      acc[status].push(item);
      return acc;
    }, {});
  };

  const handleReannotationClick = async (item) => {
    setPrevSelected(true)
    setSelected(item)
    console.log(item)
    await getSelectedLink(item)
  }

  const handleDeleteAnnotation = async () => {
    console.log(deleteSelect)

    if (!deleteSelect) {
      return
    }

    axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/deleteResults`, { 'agency': deleteSelect.agency.S, 'id': deleteSelect.id.S })
      .then((data) => {
        // Send success notif
      })
      .catch((err) => {
        // Send error notif
        console.log(`Errored in grabbing annotation list: ${err}`)
        setError(true)
        return
      })
  }

  return (
    <div style={{ backgroundColor: '#2B2B3D' }}>
      {/* Annotation */}
      {selected && selectedLink && (
        <div>
          <Annotation
            url={selectedLink}
            agency={props.agency}
            handleFinish={onAnnotationFinish}
            intersections={props.intersections}
            info={selected}
            prevSelected={prevSelected}
            startDate={selected?.start_date || ""}
          />
        </div>
      )}

      {/* List obtained */}
      {!selected && list && (
        <div
          style={{
            backgroundColor: '#2B2B3D',
            display: 'flex',
            maxHeight: '100vh',
            flexDirection: 'column',
            paddingLeft: '20%',
            paddingRight: '20%',
            paddingTop: '50px',
            overflowY: 'auto',
          }}
        >
          {Object.keys(list).map((status) => (
            <div key={status}>
              {/* This one liner will just capitalize the first letter */}
              <h2>{status.charAt(0).toUpperCase() + status.slice(1)}</h2>
              <ul style={{ paddingLeft: '0' }}>
                {list[status].map((item, index) => (
                  <div
                    onClick={() => {
                      if (status === 'annotate') {
                        onSelect(item);
                      }
                    }}
                    key={index}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      border: '1px solid #ccc',
                      borderRadius: '10px',
                      padding: '10px',
                      margin: '10px',
                      backgroundColor: '#2B2B3D',
                      color: 'white',
                      cursor: 'pointer',
                      marginLeft: 'none',
                    }}
                    className="translate-right"
                  >
                    <div style={{ flexGrow: 1, paddingRight: '10px' }}>
                      <div style={{ fontWeight: 'bold' }}>{item.intersection.S}</div>
                      <div style={{ color: '#888' }}>
                        {new Date(item.time.S).toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit',
                        })}
                      </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      {status === 'annotate' && <AiOutlineArrowRight size={28} />}
                      {(status === 'track' || status === 'analytics') && <CircularProgress style={{color: 'white', width: '24px', height: 'auto'}} />}
                      {status === 'done' && (
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <EditIcon size={30} onClick={() => handleReannotationClick(item)} />
                          <DeleteIcon
                            size={30}
                            onClick={() => {
                              document.getElementById('confirm-modal').showModal();
                              setDeleteSelect(item);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </ul>
            </div>
          ))}
        </div>
      )}

      {/* No video in metadata table */}
      {!selected && list && list.length === 0 && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <h1>No videos uploaded!</h1>
          <h3>Select the video upload icon on the dashboard page to upload.</h3>
        </div>
      )}

      {/* Loading state */}
      {!selected && !list && (
        <div
          style={{
            backgroundColor: '#2B2B3D',
            display: 'flex',
            margin: '30px',
            width: 'full',
            justifyItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <h3>Loading...</h3>
          <LinearProgress
            sx={{
              backgroundColor: 'white',
              '& .MuiLinearProgress-bar': {
                backgroundColor: '#00A19C',
              },
              marginTop: '5px',
            }}
          />
        </div>
      )}

      {/* Error */}
      {error && (
        <div style={{ marginTop: '40px', display: 'flex' }}>
          <h1>Looks like we ran into an error.</h1>
          <p>Try reloading the page.</p>
        </div>
      )}

      {/* Used to confirm deletion */}
      <dialog id="confirm-modal">
        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px', marginRight: '10px', minWidth: '200px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 'none', marginBottom: '5px', alignItems: 'center' }}>
            <h4>Delete Results?</h4>
            <button style={{ padding: '0', marginRight: '5px' }} onClick={() => { document.getElementById('confirm-modal').close() }}>x</button>
          </div>
          <div>
            <p>This will delete all results and video data for this video. <b>This cannot be undone.</b></p>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '5px', marginTop: '10px' }}>
            <button className="button" onClick={() => document.getElementById('confirm-modal').close()}>Cancel</button>
            <button className="button" onClick={() => handleDeleteAnnotation()} style={{ backgroundColor: 'var(--accent-error-dark)' }}>Delete</button>
          </div>
        </div>
      </dialog>
    </div>
  );


};

export default AnnotationList;
