import { useState, useEffect } from "react";
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios'

import { addNotification } from "../../util/addNotification";
import { addToLogs } from '../../util/logging'

export default function VideoUpload({ onClose, intersections, agency, intersectionToggled, updateUploadProgress }) {

    // upload: ready for upload, loading: loading video in, confirm: confirm & select intersection
    const [uploadState, setUploadState] = useState('upload')
    const [fileToLoad, setFileToLoad] = useState()
    // outside, inside, file
    const [dragState, setDragState] = useState('outside')
    const [videoURL, setVideoURL] = useState()
    const [intersectionSelected, setIntersectionSelected] = useState(intersections?.[0]?.id)
    const [loading, setLoading] = useState(false)
    const [startDate, setStartDate] = useState(new Date().toISOString().slice(0, 19))

    useEffect(() => {

        const handleDrop = (event) => {
            event.preventDefault();

            const files = event.dataTransfer.files;
            console.log(files)
            if (files[0].type.startsWith('video/')) {
                setDragState('file')
                setFileToLoad(files[0])
            }
        }

        const handleDragEnter = (event) => {
            event.preventDefault();
            console.log(event.dataTransfer.files)
            setDragState('inside')
        }

        const handleDragLeave = (event) => {
            event.preventDefault();
            setDragState('outside')
        }

        const div = document.getElementById('div-drop')

        div.addEventListener('drop', handleDrop)
        div.addEventListener('dragenter', handleDragEnter)
        div.addEventListener('dragleave', handleDragLeave)

        return () => {
            div.removeEventListener('drop', handleDrop)
            div.removeEventListener('dragenter', handleDragEnter)
            div.removeEventListener('dragleave', handleDragLeave)
        }
    }, [])


    function closeModal() {
        setUploadState('upload')
        setFileToLoad(null)
        onClose()
    }

    function cancelFileUpload() {
        setDragState('outside')
        setFileToLoad()
        setUploadState('upload')
        onClose()
    }

    function onFileUpload() {
        const file = document.getElementById('fileToLoad').files[0]
        console.log(file)

        if (file.type.startsWith('video/')) {
            setDragState('file')
            setFileToLoad(file)
        }
    }

    async function handleMove() {
        // TODO: Sanity checks on file type/ size etc
        if (fileToLoad) {
            const fileURL = URL.createObjectURL(fileToLoad);
            setVideoURL(fileURL)
            setUploadState('confirm')
        }
    }

    function arrayBufferToBase64(buffer) {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }

    async function handleUpload() {
        try {
            if (!fileToLoad) {
                console.log('No file uploaded');
                return;
            }
    
            const bucketName = "etalyc-deepstream-video-processing";
            const key = `inputs/${fileToLoad.name}`;
            const partSize = 8 * 1024 * 1024; // 8MB
    
            const metadata = {
                "name": fileToLoad.name,
                "fileType": fileToLoad.type,
                "intersection": intersectionSelected,
                "owner": agency,
                "startDate": startDate
            };
    
            setLoading(true);
            closeModal();
    
            // Initiate upload
            const initiateResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/initiateUpload`, {
                bucketName,
                key,
                metadata
            });
    
            const uploadId = initiateResponse.data.uploadId;
    
            const fileReader = new FileReader();
            const arrayBuffer = await new Promise((resolve, reject) => {
                fileReader.onloadend = () => resolve(fileReader.result);
                fileReader.onerror = () => reject(fileReader.error);
                fileReader.readAsArrayBuffer(fileToLoad);
            });
    
            const uploadParts = [];
            const totalParts = Math.ceil(arrayBuffer.byteLength / partSize);
    
            // Upload each part
            for (let partNumber = 1; partNumber <= totalParts; partNumber++) {
                const start = (partNumber - 1) * partSize;
                const end = Math.min(start + partSize, arrayBuffer.byteLength);
                const partBody = arrayBuffer.slice(start, end);

                const formData = new FormData();
                formData.append("bucketName", bucketName);
                formData.append("key", key);
                formData.append("partNumber", partNumber);
                formData.append("uploadId", uploadId);
                formData.append("body", new Blob([partBody])); // Send raw binary data as Blob


                const uploadPartResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/uploadPart`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });

    
                uploadParts.push({
                    ETag: uploadPartResponse.data.ETag,
                    PartNumber: partNumber,
                });
    
                updateUploadProgress(partNumber / totalParts);
            }
    
            // Complete the upload
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/completeUpload`, {
                bucketName,
                key,
                uploadId,
                parts: uploadParts,
            });
    
            addNotification("Video Uploaded", 'Upload successful! We\'re processing your video now. Come back later to annotate.', 'success', agency, intersectionSelected);
            setLoading(false);
        } catch (err) {
            console.error(err);
            addToLogs("ERROR", "Video upload failed.", `Video for ${agency}, ${fileToLoad.name} failed.\nError: ${err}`, "VideoUpload", agency);
            setLoading(false);
    
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/addNotification`, {
                agency: agency,
                title: "Upload Unsuccessful",
                content: 'Error uploading video. Please try again later',
                severity: "warning",
                redirect: intersectionSelected
            });
        }
    }
    

    /* Depreciated 2024-05-29. Remove later once upload function is fully robust. */
    async function handleUploadAPI() {
        try {
            // Check if a file is selected
            if (!fileToLoad) {
                console.log('No file uploaded');
                return;
            }

            // Set up headers for the HTTP request
            const config = {
                headers: {
                    'Content-Type': fileToLoad.type,
                },
            };

            try {
                setLoading(true)

                await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/addNotification`, {
                    agency: agency,
                    title: "Uploading Video...",
                    content: 'Working on uploading your video.',
                    severity: "info",
                    redirect: intersectionSelected
                });

                // Close the modal after successful upload and notification
                closeModal();

                setLoading(false)

                // Send a POST request to get a pre-signed URL for file upload
                console.log("Start Date", startDate)
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/videoUpload`, {
                    "name": fileToLoad.name,
                    "fileType": fileToLoad.type,
                    "intersection": intersectionSelected,
                    "owner": agency,
                    "startDate": startDate
                });

                console.log(`Triggering upload for: ${response.data.uploadUrl} with data: ${response.data}`);

                // Send a PUT request to the pre-signed URL to upload the file
                await axios.put(response.data.uploadUrl, fileToLoad, config);



                // Send a POST request to add a notification about the successful upload
                await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/addNotification`, {
                    agency: agency,
                    title: "Video Uploaded",
                    content: 'Upload successful! We\'re processing your video now. Come back later to annotate.',
                    severity: "success",
                    redirect: intersectionSelected
                });


            } catch (err) {

                // Send a POST request to add a notification about unsuccessful upload
                await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/addNotification`, {
                    agency: agency,
                    title: "Upload Unsuccessful",
                    content: 'Error uploading video. Please try again later',
                    severity: "warning",
                    redirect: intersectionSelected
                });

                console.log(err);
            }

        } catch (err) {
            console.log(`Errored in video upload: ${err}`);
        }
    }

    function cancelFileUpload() {
        setDragState('outside')
        setFileToLoad()
        setUploadState('upload')
        onClose()
    }

    const handleStartDate = (val) => {
        val = val.length === 16 ? `${val.slice(0, 16)}:00` : val
        setStartDate(val)
    }


    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '510px', height: 'auto', padding: '10px' }}>
            {/* Top bar */}
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: "30px" }}>
                <h3 className="display-xs" style={{ fontWeight: '500' }}>Video Upload</h3>
                <CloseIcon style={{
                    color: 'white', border: 'none', background: 'transparent', cursor: 'pointer',
                    hover: { backgroundColor: 'white' }
                }} onClick={() => { closeModal() }} />
            </div>

            {uploadState === 'upload' && <div>
                <p className="text-sm" style={{ color: 'var(--neutral-300)', fontWeight: '300' }}>Upload your intersection's video.</p>
                <div style={{
                    display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'var(--neutral-400)', width: '100%', height: '100px', borderRadius: '10px',
                    marginTop: '5px', marginBottom: '10px', position: 'relative', border: '3px dashed var(--neutral-600)'
                }} id='div-drop' onDragOver={(e) => e.preventDefault()}>
                    <input type='file' accept="video/*" multiple={false} id='fileToLoad' onChange={(e) => onFileUpload(e)}
                        style={{ width: '100%', height: '100%', opacity: 0, position: 'absolute', cursor: 'pointer' }} />
                    <div>
                        {dragState === 'outside' && <p>Drag file or <b>click</b> to upload.</p>}
                        {dragState === 'inside' && <p>Drop file here.</p>}
                        {dragState === 'file' && <div style={{}}>
                            <p>Drag file or <b>click</b> to upload.</p>
                        </div>}
                    </div>
                </div>
                {fileToLoad && <div style={{ marginBottom: '20px' }}>
                    <p className="text-md">Uploaded file:</p>
                    <p className="text-sm" style={{ color: 'var(--neutral-200)', fontWeight: '300' }}>{fileToLoad?.name}</p>
                </div>}
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                    <button
                        onClick={cancelFileUpload}
                        style={{
                            padding: '10px 20px',
                            backgroundColor: 'gray',
                            color: 'white',
                            border: 'none',
                            borderRadius: '5px',
                        }}
                    >Cancel</button>
                    <button
                        onClick={handleMove}
                        disabled={!fileToLoad}
                        style={{
                            padding: '10px 20px',
                            backgroundColor: fileToLoad ? '#008000' : "rgba(0, 128, 0,0.5)",
                            color: 'white',
                            border: 'none',
                            borderRadius: '5px',
                        }}
                    >Next</button>
                </div>
            </div>
            }

            {uploadState === 'confirm' && <div>
                <video id="thumbnail" width={500} src={videoURL}></video>
                <div className='annotation-label-input-div' style={{ paddingTop: '10px', paddingBottom: '20px' }}>
                    <label className='annotation-label'>Starting Date and Time</label>
                    <input className='annotation-input' type='datetime-local' name={'start_date'} step={'1'} defaultValue={startDate} onChange={e => { handleStartDate(e.target.value) }} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <button
                        onClick={() => setUploadState('upload')}
                        disabled={loading}
                        style={{
                            padding: '10px 20px',
                            backgroundColor: 'gray',
                            color: 'white',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: loading ? 'not-allowed' : 'pointer',
                        }}
                    >Back</button>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px', marginRight: '8px' }}>
                            <p style={{ fontSize: '14px' }}>Intersection:</p>
                            <select id='intersection-select'
                                required
                                value={intersectionSelected}
                                onChange={(e) => setIntersectionSelected(e.target.value)}
                                style={{
                                    padding: '10px',
                                    border: 'none',
                                    borderRadius: '5px',
                                }}
                            >
                                {intersections && Array.isArray(intersections) &&
                                    intersections?.map((intersection) => (
                                        <option key={intersection.id} value={intersection.id}>
                                            {intersection.id}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                        <button
                            style={{
                                padding: '10px 20px',
                                backgroundColor: loading ? 'gray' : 'green',
                                color: 'white',
                                border: 'none',
                                borderRadius: '5px',
                                cursor: loading ? 'not-allowed' : 'pointer',
                            }}
                            disabled={loading}
                            onClick={handleUpload}
                        >Upload</button>
                    </div>
                </div>

            </div>}

        </div>
    )

}