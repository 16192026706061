import React, { useState, useEffect } from 'react';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import axios from 'axios'
import { addToLogs } from '../util/logging';

// TODO: Rework to make dynamic. No more menu/ table height
const OverviewTable = ({ tableHeight, setMenuHeight, setMenuWidth, menuAdjust, startDate, endDate, agency, weekends }) => {
    const [containsData, setContainsData] = useState(true);
    const [intersections, setIntersections] = useState([]);
    const [sortConfig, setSortConfig] = useState({ category: 'all', direction: '', areaEffect: '' });

    // Fetches data from endpt, converts into table-friendly data
    useEffect(() => {
        fetchInfo()
    }, [startDate, endDate]);

    const fetchInfo = async () => {
        if (process.env.REACT_APP_DEMO_MODE === 'true') {
            fetch(process.env.PUBLIC_URL + '/sample_data.json')
                .then(response => response.json())
                .then(data => {
                    const transformedData = data.body.intersection_data.map(intersectionObject => {
                        const id = Object.keys(intersectionObject)[0];
                        const details = intersectionObject[id];
                        return {
                            name: id,
                            general: details,
                            long_duration: details.long_duration,
                            red_light_running: details.red_light_running,
                            yellow_light_running: details.yellow_light_running,
                            gap_acceptance: details.gap_acceptance,
                            split_failure: details.split_failure,
                            pedestrian_conflict: details.pedestrian_conflict,
                        };
                    });
                    setIntersections(transformedData);
                    setContainsData(true);
                })
                .catch(() => {
                    setContainsData(false);
                });
        } else {
            await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/systemOverview`,
                {
                    agency: agency,
                    amount: "all",
                    start_date: startDate,
                    end_date: endDate,
                    weekends: weekends
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
                .then(data => {
                    const formattedData = data.data
                    
                    if(formattedData.statusCode !== 200) {
                        throw new Error(formattedData.body)
                    }

                    const transformedData = formattedData.body.intersection_data.map(intersectionObject => {
                        const id = Object.keys(intersectionObject)[0];
                        const details = intersectionObject[id];
                        return {
                            name: id,
                            general: details,
                            long_duration: details.long_duration,
                            red_light_running: details.red_light_running,
                            yellow_light_running: details.yellow_light_running,
                            gap_acceptance: details.gap_acceptance,
                            split_failure: details.split_failure,
                            pedestrian_conflict: details.pedestrian_conflict,
                        };
                    });

                    setIntersections(transformedData);
                    setContainsData(true);
                })
                .catch((err) => {
                    setContainsData(false);
                    addToLogs("WARNING", "Failed to fetch system overview data.", `Error: ${err}.\nstart_date: ${startDate}, end_date: ${endDate}, weekends: ${weekends}`, "OverviewTable", agency)
                    console.error(err)
                });
        }
    }

    const categories = [
        // categories for top
        [
            { id: 'all', name: 'All' },
            { id: 'long_duration', name: 'Long Duration' },
            { id: 'red_light_running', name: 'Red Light Running' },
            { id: 'yellow_light_running', name: 'Yellow Light Running' },
            { id: 'gap_acceptance', name: 'Gap Acceptance' },
            { id: 'split_failure', name: 'Split Failure' },
            { id: 'pedestrian_conflict', name: 'Ped Conflict' }
        ],
        // areaEffect
        [
            { id: 'name', name: 'Intersection Name' },
            { id: 'mean', name: 'Mean' },
            { id: 'percent_cycles', name: '% of Cycles' },
            { id: 'max', name: 'Max' },
            { id: 'peak_time', name: 'Peak Time (time value)' },
            { id: 'peak_direction', name: 'Peak Direction (NW/NE)' }
        ],
        // categories for general
        [
            { id: 'name', name: 'Name' },
            { id: 'long_duration.mean', name: 'Long Duration (mean)' },
            { id: 'red_light_running.percent_cycles', name: 'Red Light Running (% of vehicles)' },
            { id: 'yellow_light_running.percent_cycles', name: 'Yellow Light Running (% of vehicles)' },
            { id: 'gap_acceptance.mean', name: 'Gap Acceptance (mean)' },
            { id: 'split_failure.percent_cycles', name: 'Split Failure (% of cycles)' },
            { id: 'pedestrian_conflict.percent_cycles', name: 'Ped Conflict (% of cycles)' }
        ],
    ];

    const displayData = (intersections) => {
        if (!sortConfig || sortConfig.direction === '' || sortConfig.areaEffect === '') {
            return intersections;
        }
        const sortFunc = (a, b) => {
            try {
                let aVal, bVal;
                if (sortConfig.areaEffect === 'name') {
                    aVal = a['name']
                    bVal = b['name']
                } else if (sortConfig.category === 'all') {
                    const [category, subCategory] = sortConfig.areaEffect.split('.');
                    aVal = a.general[category][subCategory];
                    bVal = b.general[category][subCategory];
                } else {
                    aVal = a[sortConfig.category][sortConfig.areaEffect]
                    bVal = b[sortConfig.category][sortConfig.areaEffect]
                }
                // at this point, aVal and bVal are both the same value type; aVal implies bVal
                if (typeof aVal === 'string') {
                    switch (true) {
                        case (aVal.includes('%')): // percent_cycles, max
                            aVal = parseFloat(aVal.replace('%', '')) / 100;
                            bVal = parseFloat(bVal.replace('%', '')) / 100;
                            break;
                        case (aVal.includes('B')): // peak_direction
                            const directionOrder = { 'NB': 1, 'SB': 2, 'EB': 3, 'WB': 4 };
                            aVal = directionOrder[aVal];
                            bVal = directionOrder[bVal];
                            break;
                        default: // mean, peak_time, name (assuming name is ALWAYS an integer val)
                            aVal = parseFloat(aVal); bVal = parseFloat(bVal);
                            break;
                    }
                }
                return sortConfig.direction === 'ascending' ? aVal - bVal : bVal - aVal;
            } catch (err) {
                return sortConfig.direction === ''; // reset to default state
            }
        };
        const sortedData = [...intersections].sort(sortFunc);
        return sortedData;
    }

    const handleSortIconClick = (areaEffect) => {
        updateSortConfig(sortConfig.category, areaEffect);
    };

    const updateSortConfig = (category = sortConfig.category, newAreaEffect = sortConfig.areaEffect) => {
        if (category !== sortConfig.category) {
            setSortConfig({ category, direction: 'ascending', areaEffect: newAreaEffect })
        }
        let newDirection = 'ascending';
        if (sortConfig.areaEffect === newAreaEffect) {
            if (sortConfig.direction === 'ascending') {
                newDirection = 'descending';
            } else if (sortConfig.direction === 'descending') {
                newDirection = '';
            }
        }
        setSortConfig({ category, direction: newDirection, areaEffect: newAreaEffect });
    };

    const getSortIcon = (itemId) => {
        const iconStyle = { flexShrink: 0, borderRadius: '2px', fontSize: '18px', padding: '2px', boxShadow: '2px' };

        if (sortConfig.direction === 'ascending' && sortConfig.areaEffect === itemId) {
            return <ArrowDropUpIcon style={iconStyle} onClick={() => handleSortIconClick(itemId)} />;
        } else if (sortConfig.direction === 'descending' && sortConfig.areaEffect === itemId) {
            return <ArrowDropDownIcon style={iconStyle} onClick={() => handleSortIconClick(itemId)} />;
        } else {
            return <ImportExportIcon style={iconStyle} onClick={() => handleSortIconClick(itemId)} />;
        }
    };

    useEffect(() => {
        if (!containsData) {
            setMenuHeight('600px');
            setMenuWidth('340px');
        }
    }, [containsData])

    return (
        <div style={{ justifyContent: 'center', position: 'relative', top: tableHeight, marginBottom: `${containsData ? '': '50px'}` }}>
            {containsData ? (
                <div>
                    {/* navbar */}
                    <nav style={{ width: '100%', borderBottom: '1px solid #373848', justifyContent: 'space-around', display: 'flex', borderRadius: '2px' }}>
                        {categories[0].map(category => (
                            <button key={category.id} onClick={() => updateSortConfig(category.id)} style={{
                                outline: 'none',
                                background: 'transparent',
                                border: 'none',
                                padding: '10px',
                                fontFamily: 'Inter',
                                fontSize: '16px',
                                borderRadius: 0,
                                position: 'relative',
                                zIndex: sortConfig.category === category.id ? 1 : 0,
                                marginBottom: '-1px',
                                borderBottom: sortConfig.category === category.id ? '2px solid #00A19C' : '1px solid #373848',
                                color: sortConfig.category === category.id ? '#00A19C' : 'white',
                            }}>{category.name}</button>
                        ))}
                    </nav>

                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', fontFamily: 'Inter', borderRadius: '2px', color: '#D0D5DD' }}>

                        {/* flex header */}
                        <div style={{
                            display: 'flex',
                            borderBottom: '1px solid #2B2B3D',
                            fontSize: '14px',
                            padding: '20px 0 10px',
                            alignItems: 'center'
                        }}>
                            {categories[sortConfig.category === 'all' ? 2 : 1].map(item => (
                                <div key={item.id} style={{
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    paddingLeft: '10px'
                                }}>
                                    <span style={{ marginRight: '5px', textAlign: 'left', flex: '1' }}>{item.name}</span>
                                    <div style={{ flexShrink: 0 }}>
                                        {getSortIcon(item.id)}
                                    </div>
                                </div>
                            ))}
                        </div>

                        { /* flex area for body rows */}
                        <div style={{
                            marginTop: '10px',
                            overflowY: intersections.length > 6 ? 'auto' : 'hidden',
                            maxHeight: intersections.length > 6 ? (menuAdjust ? '300px' : '250px') : 'auto'
                        }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                {displayData(intersections).map((intersection, index) => {
                                    const rowContent = sortConfig.category === 'all' ? [
                                        intersection.name,
                                        intersection.long_duration.mean,
                                        intersection.red_light_running.percent_cycles,
                                        intersection.yellow_light_running.percent_cycles,
                                        intersection.gap_acceptance.mean,
                                        intersection.split_failure.percent_cycles,
                                        intersection.pedestrian_conflict.percent_cycles
                                    ] : [
                                        intersection.name,
                                        intersection[sortConfig.category].mean,
                                        intersection[sortConfig.category].percent_cycles,
                                        intersection[sortConfig.category].max,
                                        intersection[sortConfig.category].peak_time,
                                        intersection[sortConfig.category].peak_direction
                                    ];

                                    return (
                                        <div key={index} style={{ display: 'flex', borderBottom: '1px solid #2B2B3D', padding: '15px 0' }}>
                                            {rowContent.map((data, dataIndex) => (
                                                <div key={dataIndex} style={{
                                                    flex: 1,
                                                    textAlign: 'left',
                                                    paddingLeft: dataIndex === 0 ? '10px' : '0'
                                                }}>
                                                    {data}
                                                </div>
                                            ))}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div style={{
                    position: 'absolute',
                    width: '270px',
                    top: '2px',
                    left: '20px',
                    backgroundColor: '#2B2B3D',
                    padding: '14px',
                    display: 'inline-block',
                    borderRadius: '5px'
                }}>
                    <span style={{ color: '#D0D5DD', marginLeft: '20px', fontWeight: '400', lineHeight: '20px' }}>No data is uploaded/detected.</span>
                </div>
            )}
        </div>
    );


}
export default OverviewTable;

