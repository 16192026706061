import axios from 'axios';

// Timestamp is added by function
async function addToLogs(level, message, details, subresource, agency) {
    const messageString = `[${level.toUpperCase()}]: ${message}\n${details}\nfrontend, ${subresource}.`
    const date = getFormattedDate()
    const logStreamName = `${date}${agency.toUpperCase()}`

    // Send to API
    try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/addLog`, {
            'logStreamName': logStreamName,
            'message': messageString
        })
        if (response.status !== 200) {
            console.error(`Errored sending log: ${response}`)
        }
    } catch (err) {
        console.error(err)
    }
}

function getFormattedDate() {
    const date = new Date();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();

    return month + day + year;
}

export { addToLogs }